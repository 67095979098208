import { loadGraphSetStructure } from 'business/data-analysis/pages/graph/hooks/graph-context/action/load-graph-set-structure';
import { updateGraphDisplayType } from 'business/data-analysis/pages/graph/hooks/graph-context/action/update-graph-display-type';

import { addGraph } from './action/add-graph';
import { addParameters } from './action/add-parameters';
import { deleteGraph } from './action/delete-graph';
import { removeParameters } from './action/remove-parameter';
import { renameGraph } from './action/rename-graph';
import { resetGraphSet } from './action/reset-graph-set';
import { setCurrentGraphId } from './action/set-current-graph-id';
import { updateLoadingStatus } from './action/update-loading-status';
import { updateParameter } from './action/update-parameter';
import { updateValues } from './action/update-values';
import {
  GraphSetActionType,
  GraphSetDispatchActions,
  GraphPageState,
} from './types';

export const createGraphSetReducer = () => {
  return (
    graphSet: GraphPageState,
    action: GraphSetActionType,
  ): GraphPageState => {
    switch (action.type) {
      case GraphSetDispatchActions.AddGraph: {
        return addGraph(graphSet);
      }
      case GraphSetDispatchActions.AddParameters: {
        return addParameters(graphSet, action.graphId, action.parameters);
      }
      case GraphSetDispatchActions.DeleteGraph: {
        return deleteGraph(graphSet, action.graphId);
      }
      case GraphSetDispatchActions.LoadGraphSetStructure: {
        return loadGraphSetStructure(graphSet, action.structure, action.save);
      }
      case GraphSetDispatchActions.RemoveParameters: {
        return removeParameters(
          graphSet,
          action.graphId,
          action.parameterUniqueIds,
        );
      }
      case GraphSetDispatchActions.RenameGraph: {
        return renameGraph(graphSet, action.graphId, action.title);
      }
      case GraphSetDispatchActions.ResetGraphSet: {
        return resetGraphSet(action.mode);
      }
      case GraphSetDispatchActions.SetCurrentGraphId: {
        return setCurrentGraphId(graphSet, action.graphId);
      }
      case GraphSetDispatchActions.UpdateDisplayType: {
        return updateGraphDisplayType(
          graphSet,
          action.graphId,
          action.displayType,
        );
      }
      case GraphSetDispatchActions.UpdateParameter: {
        return updateParameter(
          graphSet,
          action.graphId,
          action.parameterUniqueId,
          action.update,
        );
      }
      case GraphSetDispatchActions.UpdateValues: {
        return updateValues(graphSet, action.values);
      }
      case GraphSetDispatchActions.UpdateLoadingStatus: {
        return updateLoadingStatus(graphSet, action.isLoading);
      }
    }
  };
};

import { Alert } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import invariant from 'tiny-invariant';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { ProgressHistoryGraph } from 'business/dashboard/components/progress-history-graph';
import { useProgressHistory } from 'business/dashboard/hooks/use-progress-history';
import { getEmptyProgressHistoryGraphPoint } from 'business/dashboard/services/getEmptyProgressHistoryGraphPoint';
import { addMissingDaysForGraphPerDay } from 'technical/graph/addMissingDaysForGraphPerDay';
import { SIMPLE_DATE_FORMAT } from 'technical/string/formatter';
import { isUndefinedOrNull } from 'technical/utils/is-null-or-undefined';
import Loader from 'ui/loader';

const ProgressHistoryContainer: React.FC = () => {
  const { currentConstructionSite } = useAppContext();
  const { t } = useTranslation();

  invariant(currentConstructionSite, 'No constructionSite id');

  const today = dayjs();
  const sevenDaysAgo = today.subtract(6, 'day');

  const { loading, error, progressHistory } = useProgressHistory({
    // Format like this to avoid issues with timezone, that could end in today written as yesterday
    startDate: sevenDaysAgo.format(SIMPLE_DATE_FORMAT),
    endDate: today.format(SIMPLE_DATE_FORMAT),
    constructionSiteId: currentConstructionSite.id,
  });

  if (error) {
    return <Alert message={t('errors.error_generic')} type="error" />;
  }

  if (loading || isUndefinedOrNull(progressHistory)) {
    return <Loader />;
  }

  const progressHistoryWithMissingDates = addMissingDaysForGraphPerDay(
    [sevenDaysAgo, today],
    progressHistory.graphData.map(({ date, ...rest }) => ({
      ...rest,
      date: dayjs(date).toDate(),
    })),
    getEmptyProgressHistoryGraphPoint,
  );

  return (
    <ProgressHistoryGraph
      progressHistoryGraphData={progressHistoryWithMissingDates}
    />
  );
};

export default ProgressHistoryContainer;

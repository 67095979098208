import { GraphState } from 'business/data-analysis/pages/graph/hooks/graph-context/types';

export const getNewGraphNumber = (graphs: GraphState[]) => {
  for (let i = 1; i <= graphs.length; i++) {
    const existingGraph = graphs.find((graph) => graph.graphNumber === i);
    if (!existingGraph) {
      return i;
    }
  }

  return graphs.length + 1;
};

import { App, TablePaginationConfig } from 'antd';

import { useSteeringFilters } from 'business/steering/providers/steering-filters-provider';
import {
  Parameter_Ring_Bool_Exp,
  useGetSteeringDataQuery,
} from 'generated/graphql';
import { validateRingRange } from 'technical/validation/rules';

export const useGetDataQuery = (
  constructionSiteId: string,
  pagination?: Pick<TablePaginationConfig, 'current' | 'pageSize'>,
) => {
  const { message } = App.useApp();
  const filters = useSteeringFilters();

  const combinedFilters: Parameter_Ring_Bool_Exp = {};

  if (
    filters.filterType === 'ring' &&
    filters.ringRange &&
    validateRingRange(filters.ringRange)
  ) {
    const [min, max] = filters.ringRange;
    combinedFilters.ringNumber = {
      _gte: min === null ? undefined : min,
      _lte: max === null ? undefined : max,
    };
  }

  if (filters.filterType === 'date' && filters.dateRange) {
    const [min, max] = filters.dateRange;
    combinedFilters.buildEnd = {
      _gte: min === null ? undefined : min.toISOString(),
      _lte: max === null ? undefined : max.toISOString(),
    };
  }

  let limit = undefined;
  let offset = undefined;

  if (pagination) {
    limit = pagination.pageSize;
    offset =
      pagination.current && pagination.pageSize
        ? (pagination.current - 1) * pagination.pageSize
        : undefined;
  }

  const { data, loading } = useGetSteeringDataQuery({
    variables: {
      constructionSiteId,
      limit,
      offset,
      filters: combinedFilters,
    },
    onError: (error) => {
      message.open({
        type: 'error',
        content: error.message,
      });
    },
  });

  return { data, loading };
};

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import classNames from 'classnames';

import Chart from 'business/data-analysis/pages/graph/components/chart';
import { EmptyGraph } from 'business/data-analysis/pages/graph/components/empty-graph';
import GraphTableView from 'business/data-analysis/pages/graph/components/graph-table-view';
import { useGraphSet } from 'business/data-analysis/pages/graph/hooks/graph-context/contexts';
import { displayTypes } from 'business/data-analysis/pages/graph/types';

import { useGraph } from './../../hooks/use-graph';
import { useGraphHasValues } from './../../hooks/use-graph-has-values';
import styles from './index.module.scss';

interface Props {
  graphId: string;
}

const GraphSection = ({ graphId }: Props) => {
  const graph = useGraph(graphId);
  const { isLoading: isGraphLoading } = useGraphSet();
  const graphHasValues = useGraphHasValues(graphId);

  if (!graphHasValues) {
    return (
      <div
        className={classNames(styles.graphSection, styles.graphEmptySection)}
      >
        <Spin
          spinning={isGraphLoading}
          wrapperClassName={styles.graphEmptySectionSpinWrapper}
          indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
        >
          <EmptyGraph />
        </Spin>
      </div>
    );
  }

  return (
    <div className={styles.graphSection}>
      <Spin
        spinning={isGraphLoading}
        wrapperClassName={styles.graphChartSectionSpinWrapper}
        indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
      >
        {graph.displayType === displayTypes.graph ? (
          <Chart graphId={graphId} />
        ) : (
          <GraphTableView graphId={graphId} />
        )}
      </Spin>
    </div>
  );
};

export default GraphSection;

import { ExportOutlined } from '@ant-design/icons';
import { App } from 'antd';
import invariant from 'tiny-invariant';
import { useTranslation } from 'translations/hooks';
import { getLocale } from 'translations/locale';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { getFilterRange } from 'business/steering/pages/table-page/components/steering-table-header/services/get-filters';
import { useSteeringFilters } from 'business/steering/providers/steering-filters-provider';
import config from 'config';
import { useSteeringTableControllerExportTable } from 'generated/apiComponents';
import { downloadFile } from 'technical/print';
import Button from 'ui/button';

const useSteeringDataExport = () => {
  const { t } = useTranslation();

  const { message } = App.useApp();
  const filters = useSteeringFilters();

  const { mutate: generateExport } = useSteeringTableControllerExportTable({
    onSuccess: (data) => {
      downloadFile(`${config.gcp.publicUri}/${data.url}`, data.fileName);
    },
    onError: () => {
      message.open({
        type: 'error',
        content: t('errors.export_download_error'),
      });
    },
  });

  const filterRange = getFilterRange(filters);

  return {
    generateExport,
    filterRange,
  };
};

export const SteeringTableHeader = () => {
  const { t } = useTranslation();
  const { currentConstructionSiteId: constructionSiteId } = useAppContext();
  invariant(constructionSiteId, 'No constructionSite id');

  const { generateExport, filterRange } = useSteeringDataExport();

  const onClick = () => {
    generateExport({
      body: {
        constructionSiteId,
        locale: getLocale(),
        ...filterRange,
      },
    });
  };

  return (
    <Button icon={<ExportOutlined />} iconPosition="end" onClick={onClick}>
      {t('common.export')}
    </Button>
  );
};

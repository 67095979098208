import { ApolloProvider } from '@apollo/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { APIProvider } from '@vis.gl/react-google-maps';
import { AgCharts } from 'ag-charts-enterprise';
import { ConfigProvider } from 'antd';
import { App as AntApp } from 'antd';
import { useTranslation } from 'translations/hooks';
import { changeLocale, getAntLocaleConfig } from 'translations/locale';

import config from 'config';
import defaultTheme from 'config/theme';
import ErrorBoundary from 'technical/error-boundary';
import apolloClient from 'technical/graphql/client';
import './App.scss';
import './main.scss';
import { queryClient } from 'technical/react-query/client';

import AppBootstrapper from './AppBootstrapper';
import { AppProvider } from './contextProviders/useAppContext';
import { PermissionsProvider } from './contextProviders/usePermissions';
import Router from './router';

const App = () => {
  const { language } = useTranslation();
  const antdLocale = getAntLocaleConfig(language);

  changeLocale(antdLocale.locale);

  AgCharts.setLicenseKey(import.meta.env.VITE_AG_CHARTS_LICENCE_KEY);

  return (
    <ErrorBoundary>
      <ApolloProvider client={apolloClient}>
        <QueryClientProvider client={queryClient}>
          <AppProvider>
            <AppBootstrapper>
              <PermissionsProvider>
                <APIProvider apiKey={config.maps.apiKey}>
                  <div className="other-screen">
                    <ConfigProvider locale={antdLocale} theme={defaultTheme}>
                      {/* Used to handle ant message, modal and notification rendereing (see here https://ant.design/components/app) */}
                      <AntApp>
                        <Router />
                      </AntApp>
                    </ConfigProvider>
                  </div>
                </APIProvider>
              </PermissionsProvider>
            </AppBootstrapper>
          </AppProvider>
        </QueryClientProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};
export default App;

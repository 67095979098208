import {
  AgCartesianAxisOptions,
  AgCartesianSeriesOptions,
  AgChartOptions,
  AgScatterSeriesTooltipRendererParams,
} from 'ag-charts-enterprise';
import { AgCharts } from 'ag-charts-react';
import { Typography } from 'antd';
import { flushSync } from 'react-dom';
import { createRoot } from 'react-dom/client';
import { useTranslation } from 'translations/hooks';

import {
  graphSideLength,
  graphKeys,
} from 'business/steering/pages/graph-page/components/steering-graph/constants';
import { useCircle } from 'business/steering/pages/graph-page/components/steering-graph/hooks/use-circle-points';
import { GraphKeys } from 'business/steering/pages/graph-page/components/steering-graph/types';
import { useSteeringFilters } from 'business/steering/providers/steering-filters-provider';
import { GetSteeringDataQuery } from 'generated/graphql';
import Card from 'ui/card';

interface SteeringGraphProps {
  data: GetSteeringDataQuery['parameter_ring'];
}

const useTooltipRenderer = () => {
  const { t } = useTranslation();

  return (params: AgScatterSeriesTooltipRendererParams<any>) => {
    const div = document.createElement('div');
    const root = createRoot(div);

    flushSync(() => {
      root.render(
        <Card>
          <Typography>
            <Typography.Text strong>
              {t('steering.graph.tooltip.ringNumber')}
            </Typography.Text>
            {params.datum.ringNumber}
          </Typography>
          <Typography>
            <Typography.Text strong>
              {t('steering.graph.tooltip.xKey', { context: params.xKey })}
            </Typography.Text>
            {params.datum[params.xKey]}
          </Typography>
          <Typography>
            <Typography.Text strong>
              {t('steering.graph.tooltip.yKey', { context: params.yKey })}
            </Typography.Text>
            {params.datum[params.yKey]}
          </Typography>
        </Card>,
      );
    });

    return div.innerHTML;
  };
};

const getAxes = (
  scale: number,
  selectedPosition: keyof typeof graphKeys,
  circleKeys: { top: GraphKeys<string>; bot: GraphKeys<string> },
) =>
  [
    {
      type: 'number',
      position: 'bottom',
      keys: [
        graphKeys[selectedPosition].abscissa,
        circleKeys.top.abscissa,
        circleKeys.bot.abscissa,
      ],
      min: -scale,
      max: scale,
      crosshair: {
        snap: false,
      },
      nice: false,
    },
    {
      type: 'number',
      position: 'left',
      keys: [
        graphKeys[selectedPosition].ordinate,
        circleKeys.top.ordinate,
        circleKeys.bot.ordinate,
      ],
      min: -scale,
      max: scale,
      crosshair: {
        snap: false,
      },
      nice: false,
    },
  ] satisfies AgCartesianAxisOptions[];

const getSeries = (
  selectedPosition: keyof typeof graphKeys,
  tooltipRenderer: (
    params: AgScatterSeriesTooltipRendererParams<any>,
  ) => string,
) =>
  [
    {
      type: 'scatter',
      xKey: graphKeys[selectedPosition].abscissa,
      yKey: graphKeys[selectedPosition].ordinate,
      tooltip: {
        renderer: tooltipRenderer,
      },
    },
  ] satisfies AgCartesianSeriesOptions[];

export const SteeringGraph = ({ data }: SteeringGraphProps) => {
  const filters = useSteeringFilters();

  const tooltipRenderer = useTooltipRenderer();

  const selectedPosition = filters.position ?? 'rear';
  const tolerance = filters.tolerance ?? 50;
  const scale = filters.scale ?? 50;

  const { circlePoints, circleSeries, circleAxeKeys } = useCircle(tolerance);

  let steeringDataAndToleranceCircle: (
    | (typeof circlePoints)[number]
    | (typeof data)[number]
  )[] = data;

  steeringDataAndToleranceCircle =
    steeringDataAndToleranceCircle.concat(circlePoints);

  const chartOptions: AgChartOptions = {
    data: steeringDataAndToleranceCircle,
    axes: getAxes(scale, selectedPosition, circleAxeKeys),
    series: [...getSeries(selectedPosition, tooltipRenderer), ...circleSeries],
    width: graphSideLength,
    height: graphSideLength,
    legend: {
      enabled: false,
    },
  };

  return <AgCharts options={chartOptions} />;
};

import { Empty, Flex } from 'antd';
import dayjs from 'dayjs';
import invariant from 'tiny-invariant';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { RingPerDayAverages } from 'business/production-and-performance/pages/ring-per-day/components/ring-per-day-averages';
import { RingPerDayGraph } from 'business/production-and-performance/pages/ring-per-day/components/ring-per-day-graph';
import moduleStyles from 'business/production-and-performance/production-and-performance.module.scss';
import { useProdAndPerfFilters } from 'business/production-and-performance/providers/prod-and-perf-filters-provider';
import { getFetchRange } from 'business/production-and-performance/services/getFetchRange';
import { useRingPerDayControllerRingPerDay } from 'generated/apiComponents';
import Loader from 'ui/loader';

export const RingPerDayGraphContainer = () => {
  const { currentConstructionSiteId } = useAppContext();
  const filters = useProdAndPerfFilters();
  invariant(currentConstructionSiteId, 'No constructionSite id');

  const fetchRange = getFetchRange(filters);

  const { data, isLoading } = useRingPerDayControllerRingPerDay(
    {
      queryParams: {
        constructionSiteId: currentConstructionSiteId,
        dayMode: filters.dayMode,
        ...fetchRange,
      },
    },
    { enabled: !!fetchRange },
  );

  if (isLoading) {
    return <Loader />;
  }

  if (!data || !data.graphData.length) {
    return <Empty />;
  }

  const averagePerDayAndShift =
    data.dayMode === 'shift' ? data.averagePerDayAndShift : null;

  return (
    <Flex vertical gap={24}>
      <RingPerDayAverages
        averagePerDay={data.averagePerDay}
        averagePerDayAndShift={averagePerDayAndShift}
        dayMode={filters.dayMode}
      />
      <div className={moduleStyles.graphContainer}>
        <RingPerDayGraph
          ringPerDayGraphData={data.graphData.map(({ date, ...rest }) => ({
            ...rest,
            date: dayjs.tz(date).toDate(),
          }))}
        />
      </div>
    </Flex>
  );
};

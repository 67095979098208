import { AgCartesianSeriesOptions } from 'ag-charts-enterprise';
import { useMemo } from 'react';

import { getCirclePointCoordinates } from 'business/steering/pages/graph-page/components/steering-graph/services';
import { GraphKeys } from 'business/steering/pages/graph-page/components/steering-graph/types';

export const useCircle = (tolerance: number) => {
  const circleAxeKeys: { top: GraphKeys<string>; bot: GraphKeys<string> } = {
    top: {
      abscissa: 'xThresholdTop',
      ordinate: 'yThresholdTop',
    },
    bot: {
      abscissa: 'xThresholdBot',
      ordinate: 'yThresholdBot',
    },
  };

  const circleSeries = [
    {
      type: 'line',
      xKey: circleAxeKeys.top.abscissa,
      yKey: circleAxeKeys.top.ordinate,
      tooltip: {
        enabled: false,
      },
      marker: {
        enabled: false,
      },
      stroke: '#ff0000',
      showInLegend: false,
    },
    {
      type: 'line',
      xKey: circleAxeKeys.bot.abscissa,
      yKey: circleAxeKeys.bot.ordinate,
      tooltip: {
        enabled: false,
      },
      marker: {
        enabled: false,
      },
      stroke: '#ff0000',
      showInLegend: false,
    },
  ] satisfies AgCartesianSeriesOptions[];

  // Circle generation is splitted in two because when 2 points in the same serie
  // get the same abscissa, ag-chart does not render them consistently
  const circlePoints = useMemo(() => {
    const positiveOrdinateCirclePoints = getCirclePointCoordinates(
      tolerance,
      100,
    ).map((point) => {
      return { xThresholdTop: point.x, yThresholdTop: point.y };
    });

    const negativeOrdinateCirclePointsBottom = getCirclePointCoordinates(
      tolerance,
      100,
      true,
    ).map((point) => {
      return { xThresholdBot: point.x, yThresholdBot: point.y };
    });

    return [
      ...positiveOrdinateCirclePoints,
      ...negativeOrdinateCirclePointsBottom,
    ];
  }, [tolerance]);

  return { circlePoints, circleSeries, circleAxeKeys };
};

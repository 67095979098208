import { RingPerDayGraphPoint } from 'business/production-and-performance/pages/ring-per-day/types';

export const getEmptyRingPerDayGraphPoint = (
  date: Date,
): RingPerDayGraphPoint => {
  return {
    date,
    shift1Count: 0,
    shift2Count: 0,
    shift3Count: 0,
    count: 0,
  };
};

import { RouteObject } from 'react-router-dom';

import Dashboard from 'business/dashboard/pages/dashboard-page';
import {
  DataAnalysisGraphPage,
  dataAnalysisGraphPageLoader,
} from 'business/data-analysis/pages/graph';
import DataAnalysisModeSelectionPage from 'business/data-analysis/pages/mode-selection';
import Indicators from 'business/indicators/pages/Indicators';
import ConnectedPage from 'business/layout/connectedPage';
import { ShiftProtectedPage } from 'business/layout/shift-protected-page';
import MultiModuleRoute from 'business/multi-module-route';
import ExcavationBuildTimePage from 'business/production-and-performance/pages/excavation-build-time';
import PlanningPage from 'business/production-and-performance/pages/planning-page';
import RingPerDayPage from 'business/production-and-performance/pages/ring-per-day';
import RingTablePage from 'business/production-and-performance/pages/ring-table';
import ProtectedRouteWithPermissions from 'business/protected-route-with-permissions';
import {
  AnonymousRoute,
  ProtectedRoute,
} from 'business/protectedRouteContainer';
import ReportAggregatedPage from 'business/report/pages/ReportAggregated';
import ReportCreatePage from 'business/report/pages/ReportCreate';
import ReportEditPage from 'business/report/pages/ReportEdit';
import ReportEditEndPage from 'business/report/pages/ReportEditEnd';
import ReportEditFinishingPage from 'business/report/pages/ReportEditFinishing';
import ReportExportAggregatedPage from 'business/report/pages/ReportExportAggregated';
import ReportExportBatchPage from 'business/report/pages/ReportExportBatch';
import ReportListPage from 'business/report/pages/ReportList';
import ReportListInProgressPage from 'business/report/pages/ReportListInProgress';
import ReportListToValidatePage from 'business/report/pages/ReportListToValidate';
import ReportListValidatedPage from 'business/report/pages/ReportListValidated';
import ReportViewPage from 'business/report/pages/ReportView';
import SteeringGraphPage from 'business/steering/pages/graph-page';
import SteeringTablePage from 'business/steering/pages/table-page';
import TaskExportPage from 'business/task/pages/TaskExport';
import TasksAnalyzer from 'business/task/pages/TasksAnalyzer';
import ShiftReportDashboard from 'business/user/pages/Dashboard';
import LandingPage from 'business/user/pages/landing';
import LoginProviderCallbackScreen from 'business/user/pages/loginProviderCallback';
import NoConstructionSite from 'business/user/pages/noConstructionSite';
import ShiftReportHome from 'business/user/pages/shiftReportHome';
import UnauthorizedPage from 'business/user/pages/unauthorized';
import { ROLES } from 'business/user/services/config';
import Routes from 'config/routes';
import { Module_Enum } from 'generated/graphql';
import ReportingNoMatch from 'technical/router/switch/reporting-no-match';

import RedirectionHome from './redirection-home';

export const commonRoutesProps: RouteObject[] = [
  {
    element: <AnonymousRoute />,
    children: [
      {
        path: Routes.App,
        element: <LandingPage />,
      },
      {
        path: Routes.LoginCallback,
        element: <LoginProviderCallbackScreen />,
      },
    ],
  },

  {
    path: '*',
    element: <ReportingNoMatch />,
  },
];

export const errorRoutes: RouteObject[] = [
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: Routes.NO_CONSTRUCTION_SITE,
        element: <NoConstructionSite />,
      },
      {
        path: Routes.UNAUTHORIZED,
        element: <UnauthorizedPage />,
      },
    ],
  },

  {
    path: Routes.NOT_FOUND,
    element: <ReportingNoMatch />,
  },
];

export const noConstructionSite = [
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: Routes.Home,
        element: <NoConstructionSite />,
      },
    ],
  },
];

export const homeAndDashboardRoutes: RouteObject[] = [
  {
    path: Routes.Home,
    element: <RedirectionHome />,
  },
  {
    element: <ConnectedPage module={Module_Enum.ShiftReport} />,
    children: [
      {
        path: Routes.ReportUserHome, // create report for shift user, switch between create or list
        element: (
          <ProtectedRouteWithPermissions
            allowedModule={Module_Enum.ShiftReport}
            allowedRoles={[ROLES.USER]}
          >
            <ShiftReportHome />
          </ProtectedRouteWithPermissions>
        ),
      },
      {
        path: Routes.ReportDashboard,
        element: (
          <ProtectedRouteWithPermissions
            allowedModule={Module_Enum.ShiftReport}
            allowedRoles={[
              ROLES.CONSTRUCTION_SITE_MANAGER,
              ROLES.CONSTRUCTION_SITE_FULL_READER,
              ROLES.CONSTRUCTION_SITE_READER,
            ]}
          >
            <ShiftReportDashboard />
          </ProtectedRouteWithPermissions>
        ),
      },
    ],
  },
  {
    element: <ConnectedPage module={'DASHBOARD'} />,
    children: [
      {
        path: Routes.Dashboard,
        element: (
          <ProtectedRouteWithPermissions
            allowedRoles={[
              ROLES.USER,
              ROLES.CONSTRUCTION_SITE_MANAGER,
              ROLES.CONSTRUCTION_SITE_FULL_READER,
              ROLES.CONSTRUCTION_SITE_READER,
            ]}
          >
            <MultiModuleRoute>
              <Dashboard />
            </MultiModuleRoute>
          </ProtectedRouteWithPermissions>
        ),
      },
    ],
  },
];

const dataAnalysisRoutes: RouteObject[] = [
  {
    element: <ConnectedPage module={Module_Enum.DataAnalysis} />,
    children: [
      {
        path: Routes.DataAnalysisGraph,
        loader: dataAnalysisGraphPageLoader,
        element: (
          <ProtectedRouteWithPermissions
            allowedModule={Module_Enum.DataAnalysis}
            allowedRoles={[ROLES.USER]}
          >
            <DataAnalysisGraphPage />
          </ProtectedRouteWithPermissions>
        ),
      },
      {
        path: Routes.DataAnalysisModeSelection,
        element: (
          <ProtectedRouteWithPermissions
            allowedModule={Module_Enum.DataAnalysis}
            allowedRoles={[ROLES.USER]}
          >
            <DataAnalysisModeSelectionPage />
          </ProtectedRouteWithPermissions>
        ),
      },
    ],
  },
];

const prodAndPerfRoutes: RouteObject[] = [
  {
    element: <ConnectedPage module={Module_Enum.ProdPerf} />,
    children: [
      {
        path: Routes.ProdPerfRingList,
        element: (
          <ProtectedRouteWithPermissions
            allowedModule={Module_Enum.ProdPerf}
            allowedRoles={[ROLES.USER, ROLES.CONSTRUCTION_SITE_MANAGER]}
          >
            <RingTablePage />
          </ProtectedRouteWithPermissions>
        ),
      },
      {
        path: Routes.ProdPerfRingPerDay,
        element: (
          <ProtectedRouteWithPermissions
            allowedModule={Module_Enum.ProdPerf}
            allowedRoles={[ROLES.USER, ROLES.CONSTRUCTION_SITE_MANAGER]}
          >
            <RingPerDayPage />
          </ProtectedRouteWithPermissions>
        ),
      },
      {
        path: Routes.ProdPerfExcavationBuildTime,
        element: (
          <ProtectedRouteWithPermissions
            allowedModule={Module_Enum.ProdPerf}
            allowedRoles={[ROLES.USER, ROLES.CONSTRUCTION_SITE_MANAGER]}
          >
            <ExcavationBuildTimePage />
          </ProtectedRouteWithPermissions>
        ),
      },
      {
        path: Routes.ProdPerfPlanning,
        element: (
          <ProtectedRouteWithPermissions
            allowedModule={Module_Enum.ProdPerf}
            allowedRoles={[ROLES.USER, ROLES.CONSTRUCTION_SITE_MANAGER]}
          >
            <PlanningPage />
          </ProtectedRouteWithPermissions>
        ),
      },
    ],
  },
];

const shiftReportRoutes: RouteObject[] = [
  {
    element: <ConnectedPage module={Module_Enum.ShiftReport} />,
    children: [
      // ReportCreate
      {
        path: Routes.ReportCreate,
        element: (
          <ProtectedRouteWithPermissions
            allowedModule={Module_Enum.ShiftReport}
            allowedRoles={[ROLES.CONSTRUCTION_SITE_MANAGER]}
          >
            <ReportCreatePage />
          </ProtectedRouteWithPermissions>
        ),
      },
    ],
  },
  {
    element: <ShiftProtectedPage />,
    children: [
      // ReportView
      {
        path: Routes.ReportView,
        element: (
          <ProtectedRouteWithPermissions
            allowedModule={Module_Enum.ShiftReport}
            allowedRoles={[
              ROLES.USER,
              ROLES.CONSTRUCTION_SITE_MANAGER,
              ROLES.CONSTRUCTION_SITE_FULL_READER,
              ROLES.CONSTRUCTION_SITE_READER,
            ]}
          >
            <ReportViewPage />
          </ProtectedRouteWithPermissions>
        ),
      },
      // ReportEdit
      {
        path: Routes.ReportEdit,
        element: (
          <ProtectedRouteWithPermissions
            allowedModule={Module_Enum.ShiftReport}
            allowedRoles={[
              ROLES.CONSTRUCTION_SITE_MANAGER,
              // Available in read for full reader
              // Inside components permission check prevent reader to edit
              ROLES.CONSTRUCTION_SITE_FULL_READER,
              ROLES.USER,
            ]}
          >
            <ReportEditPage />
          </ProtectedRouteWithPermissions>
        ),
      },
      // ReportEditFinishing
      {
        path: Routes.ReportEditFinishing,
        element: (
          <ProtectedRouteWithPermissions
            allowedModule={Module_Enum.ShiftReport}
            allowedRoles={[ROLES.USER, ROLES.CONSTRUCTION_SITE_MANAGER]}
          >
            <ReportEditFinishingPage />
          </ProtectedRouteWithPermissions>
        ),
      },
      // ReportEditEnd
      {
        path: Routes.ReportEditEnd,
        element: (
          <ProtectedRouteWithPermissions
            allowedModule={Module_Enum.ShiftReport}
            allowedRoles={[ROLES.USER, ROLES.CONSTRUCTION_SITE_MANAGER]}
          >
            <ReportEditEndPage />
          </ProtectedRouteWithPermissions>
        ),
      },
    ],
  },
];

const shiftReportListRoutes: RouteObject[] = [
  {
    element: <ConnectedPage module={Module_Enum.ShiftReport} />,
    children: [
      // ReportList
      {
        path: Routes.ReportList,
        element: (
          <ProtectedRouteWithPermissions
            allowedModule={Module_Enum.ShiftReport}
            allowedRoles={[ROLES.USER, ROLES.CONSTRUCTION_SITE_MANAGER]}
          >
            <ReportListPage />
          </ProtectedRouteWithPermissions>
        ),
      },
      // ReportListInProgress
      {
        path: Routes.ReportListInProgress,
        element: (
          <ProtectedRouteWithPermissions
            allowedModule={Module_Enum.ShiftReport}
            allowedRoles={[
              ROLES.CONSTRUCTION_SITE_MANAGER,
              ROLES.CONSTRUCTION_SITE_FULL_READER,
            ]}
          >
            <ReportListInProgressPage />
          </ProtectedRouteWithPermissions>
        ),
      },
      // ReportListToValidate
      {
        path: Routes.ReportListToValidate,
        element: (
          <ProtectedRouteWithPermissions
            allowedModule={Module_Enum.ShiftReport}
            allowedRoles={[ROLES.CONSTRUCTION_SITE_MANAGER]}
          >
            <ReportListToValidatePage />
          </ProtectedRouteWithPermissions>
        ),
      },
      // ReportListValidated
      {
        path: Routes.ReportListValidated,
        element: (
          <ProtectedRouteWithPermissions
            allowedModule={Module_Enum.ShiftReport}
            allowedRoles={[
              ROLES.CONSTRUCTION_SITE_MANAGER,
              ROLES.CONSTRUCTION_SITE_FULL_READER,
              ROLES.CONSTRUCTION_SITE_READER,
            ]}
          >
            <ReportListValidatedPage />
          </ProtectedRouteWithPermissions>
        ),
      },
    ],
  },
];

const shiftReportAnalysisRoutes: RouteObject[] = [
  {
    element: <ConnectedPage module={Module_Enum.ShiftReport} />,
    children: [
      // ReportAggregated
      {
        path: Routes.ReportAggregated,
        element: (
          <ProtectedRouteWithPermissions
            allowedModule={Module_Enum.ShiftReport}
            allowedRoles={[
              ROLES.CONSTRUCTION_SITE_MANAGER,
              ROLES.CONSTRUCTION_SITE_FULL_READER,
              ROLES.CONSTRUCTION_SITE_READER,
            ]}
          >
            <ReportAggregatedPage />
          </ProtectedRouteWithPermissions>
        ),
      },
      // ReportExportAggregated
      {
        path: Routes.ReportExportAggregated,
        element: (
          <ProtectedRouteWithPermissions
            allowedModule={Module_Enum.ShiftReport}
            allowedRoles={[
              ROLES.CONSTRUCTION_SITE_MANAGER,
              ROLES.CONSTRUCTION_SITE_FULL_READER,
              ROLES.CONSTRUCTION_SITE_READER,
            ]}
          >
            <ReportExportAggregatedPage />
          </ProtectedRouteWithPermissions>
        ),
      },
      // ReportExportBatch
      {
        path: Routes.ReportExportBatch,
        element: (
          <ProtectedRouteWithPermissions
            allowedModule={Module_Enum.ShiftReport}
            allowedRoles={[
              ROLES.CONSTRUCTION_SITE_MANAGER,
              ROLES.CONSTRUCTION_SITE_FULL_READER,
              ROLES.CONSTRUCTION_SITE_READER,
            ]}
          >
            <ReportExportBatchPage />
          </ProtectedRouteWithPermissions>
        ),
      },
      // TaskExport
      {
        path: Routes.TaskExport,
        element: (
          <ProtectedRouteWithPermissions
            allowedModule={Module_Enum.ShiftReport}
            allowedRoles={[
              ROLES.CONSTRUCTION_SITE_MANAGER,
              ROLES.CONSTRUCTION_SITE_FULL_READER,
              ROLES.CONSTRUCTION_SITE_READER,
            ]}
          >
            <TaskExportPage />
          </ProtectedRouteWithPermissions>
        ),
      },
      // TaskAnalyzer
      {
        path: Routes.TaskAnalyzer,
        element: (
          <ProtectedRouteWithPermissions
            allowedModule={Module_Enum.ShiftReport}
            allowedRoles={[
              ROLES.CONSTRUCTION_SITE_MANAGER,
              ROLES.CONSTRUCTION_SITE_FULL_READER,
              ROLES.CONSTRUCTION_SITE_READER,
            ]}
          >
            <TasksAnalyzer />
          </ProtectedRouteWithPermissions>
        ),
      },
      // Indicators
      {
        path: Routes.Indicators,
        element: (
          <ProtectedRouteWithPermissions
            allowedModule={Module_Enum.ShiftReport}
            allowedRoles={[
              ROLES.CONSTRUCTION_SITE_MANAGER,
              ROLES.CONSTRUCTION_SITE_FULL_READER,
              ROLES.CONSTRUCTION_SITE_READER,
            ]}
          >
            <Indicators />
          </ProtectedRouteWithPermissions>
        ),
      },
    ],
  },
];

const steeringRoutes: RouteObject[] = [
  {
    element: <ConnectedPage module={Module_Enum.Steering} />,
    children: [
      {
        path: Routes.SteeringTable,
        element: (
          <ProtectedRouteWithPermissions
            allowedModule={Module_Enum.Steering}
            allowedRoles={[ROLES.USER]}
          >
            <SteeringTablePage />
          </ProtectedRouteWithPermissions>
        ),
      },
      {
        path: Routes.SteeringGraph,
        element: (
          <ProtectedRouteWithPermissions
            allowedModule={Module_Enum.Steering}
            allowedRoles={[ROLES.USER]}
          >
            <SteeringGraphPage />
          </ProtectedRouteWithPermissions>
        ),
      },
    ],
  },
];

export const allModulesRoutes = [
  ...shiftReportRoutes,
  ...shiftReportListRoutes,
  ...shiftReportAnalysisRoutes,
  ...prodAndPerfRoutes,
  ...dataAnalysisRoutes,
  ...steeringRoutes,
];

export const getCirclePointCoordinates = (
  radius: number,
  step: number,
  negativeOrdinates: boolean = false,
) => {
  const circlePoints: { x: number; y: number }[] = [];

  const sign = negativeOrdinates ? -1 : 1;

  const baseAngle = (sign * Math.PI) / step;

  for (let i = 0; i < step; i++) {
    circlePoints.push({
      x: radius * Math.cos(baseAngle * i),
      y: radius * Math.sin(baseAngle * i),
    });
  }

  if (circlePoints.at(0)) {
    circlePoints.push({ x: -1 * radius, y: 0 });
  }

  return circlePoints;
};

import Icon from '@ant-design/icons';

import { CustomIconProps } from 'ui/icons/types';

const ScatterPlotSvg = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
    <g clipPath="url(#clip0_3551_1780)">
      <path
        d="M7 3.5V4.66667"
        stroke="#012169"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 9.33331V10.5"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66667 7H3.5"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 7H9.33337"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.99996 12.8334C10.2216 12.8334 12.8333 10.2217 12.8333 7.00002C12.8333 3.77836 10.2216 1.16669 6.99996 1.16669C3.7783 1.16669 1.16663 3.77836 1.16663 7.00002C1.16663 10.2217 3.7783 12.8334 6.99996 12.8334Z"
        stroke="#012169"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3551_1780">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ScatterPlotIcon = (props: CustomIconProps) => (
  <Icon component={ScatterPlotSvg} {...props} />
);

export default ScatterPlotIcon;

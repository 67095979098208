import { useTranslation } from 'translations/hooks';

import { SteeringFilters } from 'business/steering/components/steering-filters';
import { SteeringPageTemplate } from 'business/steering/components/steering-page-template';
import { SteeringTableContainer } from 'business/steering/pages/table-page/components/steering-table-container';
import { SteeringTableHeader } from 'business/steering/pages/table-page/components/steering-table-header';
import { SteeringFiltersProvider } from 'business/steering/providers/steering-filters-provider';
import { FilterCard } from 'ui/filter-card';
import { DateSelectionTypeEnum } from 'ui/form/temporality-select-input';

const SteeringTablePage = () => {
  const { t } = useTranslation();

  return (
    <SteeringFiltersProvider
      initialFilters={{
        filterType: 'date',
        dateRange: [null, null],
      }}
      noDefaultFor={[DateSelectionTypeEnum.Period]}
    >
      <SteeringPageTemplate
        title={t('steering.table.title')}
        filters={
          <FilterCard>
            <SteeringFilters noDefaultFor={[DateSelectionTypeEnum.Period]} />
          </FilterCard>
        }
        header={<SteeringTableHeader />}
      >
        <SteeringTableContainer />
      </SteeringPageTemplate>
    </SteeringFiltersProvider>
  );
};

export default SteeringTablePage;

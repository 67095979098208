import { Form } from 'antd';
import { FormProviderProps } from 'antd/lib/form/context';
import dayjs from 'dayjs';
import { PropsWithChildren, createContext, useContext, useState } from 'react';

import { DateSelectionTypeEnum } from 'ui/form/temporality-select-input';

import { SteeringRangeFilters } from './types';

const FILTERS_FORM_NAME = 'steering-filters-form';

const defaultFilters = {
  filterType: 'date',
  dateRange: [null, null],
  position: 'rear',
  scale: 50,
  tolerance: 50,
} satisfies SteeringRangeFilters;

const SteeringFiltersContext =
  createContext<SteeringRangeFilters>(defaultFilters);

const SteeringFiltersProvider = ({
  initialFilters,
  noDefaultFor,
  children,
}: PropsWithChildren<{
  initialFilters: SteeringRangeFilters;
  noDefaultFor?: DateSelectionTypeEnum[];
}>) => {
  const [filters, setFilters] = useState<SteeringRangeFilters>(initialFilters);

  const onFormChange: FormProviderProps['onFormChange'] = (
    formName,
    { forms },
  ) => {
    if (formName === FILTERS_FORM_NAME) {
      const form = forms[formName];

      const dateRange =
        form.getFieldValue('filterType') === 'date' &&
        // Check for `period` included in noDefaultFor to empty the input when
        // it is changed back to date filter type from ring filter type
        noDefaultFor?.includes(DateSelectionTypeEnum.Period)
          ? [null, null]
          : [dayjs().subtract(1, 'week'), dayjs()];

      const values = {
        dateRange,
        ringRange: [null, null],
        ...form.getFieldsValue(),
      };

      // Setting default range for both day and ring because Antd Form remove the fields value if the input is
      // not rendered. Since we conditionnaly render the dateRange or ringRange depending on the `filterType`
      // it is required to add them with defaut value to the FiltersProvider. Therefore, we're sure it is always
      // defined when the filterType is changed
      setFilters(values);
      form.setFieldsValue(values);
    }
  };

  return (
    <SteeringFiltersContext.Provider value={filters}>
      <Form.Provider onFormChange={onFormChange}>{children}</Form.Provider>
    </SteeringFiltersContext.Provider>
  );
};

const useSteeringFiltersFormName = () => FILTERS_FORM_NAME;

const useSteeringFilters = () => useContext(SteeringFiltersContext);

export {
  SteeringFiltersProvider,
  useSteeringFiltersFormName,
  useSteeringFilters,
};

import { SteeringDataFields } from 'business/steering/pages/graph-page/types';

type SelfMapping<T extends Record<keyof T, keyof T>> = {
  [K in keyof T]: K;
};

export const SteeringTableColumnKeys: Omit<
  SelfMapping<Record<keyof SteeringDataFields, keyof SteeringDataFields>>,
  'id'
> = {
  ringNumber: 'ringNumber',

  rearHorizontalPosition: 'rearHorizontalPosition',
  frontHorizontalPosition: 'frontHorizontalPosition',
  cuttingWheelHorizontalPosition: 'cuttingWheelHorizontalPosition',
  rearHorizontalTrend: 'rearHorizontalTrend',
  frontHorizontalTrend: 'frontHorizontalTrend',

  rearVerticalPosition: 'rearVerticalPosition',
  frontVerticalPosition: 'frontVerticalPosition',
  cuttingWheelVerticalPosition: 'cuttingWheelVerticalPosition',
  rearVerticalTrend: 'rearVerticalTrend',
  frontVerticalTrend: 'frontVerticalTrend',

  leftSkirtClearance: 'leftSkirtClearance',
  rightSkirtClearance: 'rightSkirtClearance',
  upperSkirtClearance: 'upperSkirtClearance',
  lowerSkirtClearance: 'lowerSkirtClearance',
};

import Icon from '@ant-design/icons/lib/components/Icon';

import { CustomIconProps } from 'ui/icons/types';

const SteeringDataSvg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M5.66667 13.4444L1.61424 14.7953C1.31206 14.896 1 14.6711 1 14.3525V2.89191C1 2.69104 1.12854 2.51271 1.31909 2.44919L5.66667 1M5.66667 13.4444L10.3333 15M5.66667 13.4444V1M5.66667 1L10.3333 2.55556M10.3333 15L14.6809 13.5508C14.8714 13.4873 15 13.309 15 13.1081V1.64747C15 1.32894 14.688 1.10402 14.3858 1.20475L10.3333 2.55556M10.3333 15V2.55556"
      stroke="#012169"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const SteeringDataIcon = (props: CustomIconProps) => (
  <Icon component={SteeringDataSvg} {...props} />
);

export default SteeringDataIcon;

import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'translations/hooks';

import { SteeringDataFields } from 'business/steering/pages/graph-page/types';
import { SteeringTableColumnKeys } from 'business/steering/pages/table-page/components/steering-table/types';
import { notEmpty } from 'technical/utils/not-empty';

const NUMBER_CELL_ALIGN: AlignSetting = 'center';
const CELL_WIDTH = 100;

export const useSteeringTableColumns = (
  isArticulated?: boolean | null,
): ColumnsType<SteeringDataFields> => {
  const { t } = useTranslation();

  const renderCell = (value: any) => {
    return value ?? '-';
  };

  const commonColumnProps = {
    align: NUMBER_CELL_ALIGN,
    width: CELL_WIDTH,
    render: renderCell,
  };

  const horizontalPositionColumns = [
    {
      title: t('steering.table.columns.rear'),
      dataIndex: SteeringTableColumnKeys.rearHorizontalPosition,
      key: SteeringTableColumnKeys.rearHorizontalPosition,
      ...commonColumnProps,
    },
    {
      title: t('steering.table.columns.front'),
      dataIndex: SteeringTableColumnKeys.frontHorizontalPosition,
      key: SteeringTableColumnKeys.frontHorizontalPosition,
      ...commonColumnProps,
    },
    isArticulated
      ? {
          title: t('steering.table.columns.cuttingWheel'),
          dataIndex: SteeringTableColumnKeys.cuttingWheelHorizontalPosition,
          key: SteeringTableColumnKeys.cuttingWheelHorizontalPosition,
          ...commonColumnProps,
        }
      : undefined,
    {
      title: t('steering.table.columns.trend', {
        context: isArticulated ? 'rear' : undefined,
      }),
      dataIndex: SteeringTableColumnKeys.rearHorizontalTrend,
      key: SteeringTableColumnKeys.rearHorizontalTrend,
      ...commonColumnProps,
    },
    isArticulated
      ? {
          title: t('steering.table.columns.trend', { context: 'front' }),
          dataIndex: SteeringTableColumnKeys.frontHorizontalTrend,
          key: SteeringTableColumnKeys.frontHorizontalTrend,
          ...commonColumnProps,
        }
      : undefined,
  ].filter(notEmpty);

  const verticalPositionColumns = [
    {
      title: t('steering.table.columns.rear'),
      dataIndex: SteeringTableColumnKeys.rearVerticalPosition,
      key: SteeringTableColumnKeys.rearVerticalPosition,
      ...commonColumnProps,
    },
    {
      title: t('steering.table.columns.front'),
      dataIndex: SteeringTableColumnKeys.frontVerticalPosition,
      key: SteeringTableColumnKeys.frontVerticalPosition,
      ...commonColumnProps,
    },
    isArticulated
      ? {
          title: t('steering.table.columns.cuttingWheel'),
          dataIndex: SteeringTableColumnKeys.cuttingWheelVerticalPosition,
          key: SteeringTableColumnKeys.cuttingWheelVerticalPosition,
          ...commonColumnProps,
        }
      : undefined,
    {
      title: t('steering.table.columns.trend', {
        context: isArticulated ? 'rear' : undefined,
      }),
      dataIndex: SteeringTableColumnKeys.rearVerticalTrend,
      key: SteeringTableColumnKeys.rearVerticalTrend,
      ...commonColumnProps,
    },
    isArticulated
      ? {
          title: t('steering.table.columns.trend', { context: 'front' }),
          dataIndex: SteeringTableColumnKeys.frontVerticalTrend,
          key: SteeringTableColumnKeys.frontVerticalTrend,
          ...commonColumnProps,
        }
      : undefined,
  ].filter(notEmpty);

  const columns: ColumnsType<SteeringDataFields> = [
    {
      title: t('steering.table.columns.ringNumber'),
      dataIndex: SteeringTableColumnKeys.ringNumber,
      key: SteeringTableColumnKeys.ringNumber,
      align: 'center',
      width: 100,
      // Keep it visible when table is scrolled horizontally
      fixed: true,
    },
    {
      title: t('steering.table.columns.horizontalPosition'),
      key: 'horizontalPosition',
      children: horizontalPositionColumns,
    },
    {
      title: t('steering.table.columns.verticalPosition'),
      key: 'verticalPosition',
      children: verticalPositionColumns,
    },
    {
      title: t('steering.table.columns.skirtClearance'),
      key: 'skirtClearance',
      children: [
        {
          title: t('steering.table.columns.left'),
          dataIndex: SteeringTableColumnKeys.leftSkirtClearance,
          key: SteeringTableColumnKeys.leftSkirtClearance,
          ...commonColumnProps,
        },
        {
          title: t('steering.table.columns.right'),
          dataIndex: SteeringTableColumnKeys.rightSkirtClearance,
          key: SteeringTableColumnKeys.rightSkirtClearance,
          ...commonColumnProps,
        },
        {
          title: t('steering.table.columns.upper'),
          dataIndex: SteeringTableColumnKeys.upperSkirtClearance,
          key: SteeringTableColumnKeys.upperSkirtClearance,
          ...commonColumnProps,
        },
        {
          title: t('steering.table.columns.lower'),
          dataIndex: SteeringTableColumnKeys.lowerSkirtClearance,
          key: SteeringTableColumnKeys.lowerSkirtClearance,
          ...commonColumnProps,
        },
      ],
    },
  ];

  return columns;
};

import { SteeringDataKeys } from 'business/steering/constants';
import { SteeringGraphKeys } from 'business/steering/pages/graph-page/components/steering-graph/types';

const frontKeys: SteeringGraphKeys = {
  abscissa: SteeringDataKeys.frontHorizontalPosition,
  ordinate: SteeringDataKeys.frontVerticalPosition,
};

const rearKeys: SteeringGraphKeys = {
  abscissa: SteeringDataKeys.rearHorizontalPosition,
  ordinate: SteeringDataKeys.rearVerticalPosition,
};

const cuttingWheelKeys: SteeringGraphKeys = {
  abscissa: SteeringDataKeys.cuttingWheelHorizontalPosition,
  ordinate: SteeringDataKeys.cuttingWheelVerticalPosition,
};

export const graphKeys = {
  front: frontKeys,
  rear: rearKeys,
  cuttingWheel: cuttingWheelKeys,
};

export const graphSideLength = 600;

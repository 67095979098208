import { Table, TablePaginationConfig } from 'antd';

import { GetRingsQuery } from 'generated/graphql';
import { useMediaType } from 'technical/media/hooks';
import { EditableCell } from 'ui/editable-table-cell';

import { useRingTableColumns } from './hooks/use-ring-table-columns';

interface RingsTableProps {
  data?: GetRingsQuery['parameter_ring'];
  loading: boolean;
  pagination: TablePaginationConfig;
  onChangePagination: (pagination: TablePaginationConfig) => void;
  onEditionClick: (id: string | null) => void;
}

export const RingsTable: React.FC<RingsTableProps> = ({
  data = [],
  loading,
  pagination,
  onChangePagination,
  onEditionClick,
}) => {
  const columns = useRingTableColumns(onEditionClick);
  const { isTablet, isMobile } = useMediaType();

  return (
    <Table
      bordered
      scroll={isTablet || isMobile ? { x: 'max-content' } : {}}
      // 90 is the value of src/business/App.scss - $header-height
      sticky={{ offsetHeader: 90 }}
      columns={columns}
      size={'small'}
      dataSource={data}
      pagination={pagination}
      loading={loading}
      onChange={onChangePagination}
      rowKey={(record) => record.id}
      components={{
        body: { cell: EditableCell },
      }}
    />
  );
};

import { Map } from '@vis.gl/react-google-maps';
import { Alert } from 'antd';
import { useTranslation } from 'translations/hooks';

import { CompletedTrace } from 'business/dashboard/components/map/completed-trace';
import { TRACE_DEFAULT_COLOR } from 'business/dashboard/components/map/constants';
import { useData } from 'business/dashboard/components/map/hooks/use-data';
import { PointsOfInterest } from 'business/dashboard/components/map/points-of-interest';
import { TbmPosition } from 'business/dashboard/components/map/tbm-position';
import { Trace } from 'business/dashboard/components/map/trace';
import config from 'config';
import { GetTbmInfosQueryResult } from 'generated/graphql';
import Loader from 'ui/loader';

import styles from './index.module.scss';

interface Props {
  tbmInfos: GetTbmInfosQueryResult;
}

export const TbmMap = ({ tbmInfos: { data, loading, error } }: Props) => {
  const { t } = useTranslation();

  const extractedData = useData(data);

  if (error) {
    return <Alert message={t('errors.error_generic')} type="error" />;
  }

  if (loading) {
    return <Loader />;
  }

  if (!extractedData) {
    return <Alert message={t('errors.error_generic')} type="error" />;
  }

  const {
    latitude,
    longitude,
    zoom,
    traceColor,
    trace,
    position,
    angle,
    pois,
  } = extractedData;

  return (
    <div className={styles.map}>
      <Map
        mapId={config.maps.mapId}
        defaultZoom={zoom}
        defaultCenter={{ lat: latitude, lng: longitude }}
        gestureHandling="cooperative"
        streetViewControl={false}
        clickableIcons={false}
      >
        <Trace path={trace} traceColor={traceColor ?? TRACE_DEFAULT_COLOR} />
        <CompletedTrace path={trace} lastPoint={position.tbm} />
        <TbmPosition lastPoint={position.tbm} angle={angle} />
        <PointsOfInterest pois={pois} />
      </Map>
    </div>
  );
};

import { Result } from 'antd';
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import Page from 'business/layout/page';
import { login } from 'business/user/services/authentication';
import Routes from 'config/routes';
import errorReporting from 'technical/error-reporting';
import Button from 'ui/button';

export function NotFound() {
  const { t } = useTranslation();
  return (
    <Result
      status="404"
      title="404"
      subTitle={t('user.notFound')}
      extra={
        <Link to={{ pathname: Routes.Home }}>
          <Button>{t('common.to_main_page')}</Button>
        </Link>
      }
    />
  );
}

function ReportingNoMatch() {
  const { isConnected } = useAppContext();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (!isConnected) {
      login();
    }
    errorReporting.info(new Error('404'), { location: location.pathname });
  }, [isConnected, location.pathname]);

  return (
    <Page isConnected={isConnected} title={t('pages.noMatch.title')}>
      <NotFound />
    </Page>
  );
}

export default ReportingNoMatch;

import { Table, TablePaginationConfig } from 'antd';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { useSteeringTableColumns } from 'business/steering/pages/table-page/components/steering-table/hooks/use-steering-table-columns';
import { GetSteeringDataQuery } from 'generated/graphql';

interface SteeringTableProps {
  data?: GetSteeringDataQuery['parameter_ring'];
  loading: boolean;
  pagination: TablePaginationConfig;
  onChangePagination: (pagination: TablePaginationConfig) => void;
}

export const SteeringTable = ({
  data = [],
  loading,
  pagination,
  onChangePagination,
}: SteeringTableProps) => {
  const { currentConstructionSite } = useAppContext();
  const isArticulated = currentConstructionSite?.isArticulated;
  const columns = useSteeringTableColumns(isArticulated);

  return (
    <Table
      bordered
      scroll={{ x: 'max-content' }}
      // 90 is the value of src/business/App.scss - $header-height
      sticky={{ offsetHeader: 90 }}
      size={'small'}
      columns={columns}
      dataSource={data}
      pagination={pagination}
      loading={loading}
      rowKey={(record) => record.id}
      onChange={onChangePagination}
    />
  );
};

import { Flex, Tag } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'translations/hooks';

import styles from './index.module.scss';

export enum State {
  excavating = 'excavating',
  building = 'building',
  stopped = 'stopped',
}

interface DateProps {
  timeZone?: string;
}

interface StateProps {
  state?: State;
}

const stateTag = {
  [State.excavating]: {
    color: 'green',
    translationKey: 'dashboard.tbm.state.excavating',
  },
  [State.building]: {
    color: 'gold',
    translationKey: 'dashboard.tbm.state.building',
  },
  [State.stopped]: {
    color: undefined,
    translationKey: 'dashboard.tbm.state.stopped',
  },
};

const LastDate = ({ timeZone }: DateProps) => {
  const { t } = useTranslation();

  return (
    <Tag bordered={false} className={styles.corner}>
      {t('dashboard.tbm.localDate')}
      <span className={styles.date}>
        {timeZone ? dayjs(new Date()).tz(timeZone).format('LL LTS') : '-'}
      </span>
    </Tag>
  );
};

const LastState = ({ state }: StateProps) => {
  const { t } = useTranslation();
  return (
    <Tag
      color={state ? stateTag[state].color : undefined}
      bordered={false}
      className={styles.corner}
    >
      <span className={styles.statusLabel}>
        {t('dashboard.tbm.state.label')}
      </span>

      <span className={styles.statusValue}>
        {state ? t(stateTag[state].translationKey) : '-'}
      </span>
    </Tag>
  );
};

export const LastKnownState = ({ timeZone, state }: DateProps & StateProps) => {
  return (
    <Flex gap={4}>
      <LastDate timeZone={timeZone} />
      <LastState state={state} />
    </Flex>
  );
};

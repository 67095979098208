import { SteeringRangeFilters } from 'business/steering/providers/steering-filters-provider/types';
import { SIMPLE_DATE_FORMAT } from 'technical/string/formatter';

export const getFilterRange = (filters: SteeringRangeFilters) => {
  if (filters.filterType === 'date' && filters.dateRange) {
    const [startDate, endDate] = filters.dateRange;

    if (!startDate || !endDate) {
      return null;
    }
    return {
      startDate: startDate.format(SIMPLE_DATE_FORMAT),
      endDate: endDate.format(SIMPLE_DATE_FORMAT),
    };
  } else if (filters.filterType === 'ring' && filters.ringRange) {
    const [startRing, endRing] = filters.ringRange;

    if (!startRing || !endRing) {
      return null;
    }
    return {
      startRing: startRing,
      endRing: endRing,
    };
  }

  return null;
};

import {
  ControlPosition,
  InfoWindow,
  MapControl,
  useMap,
} from '@vis.gl/react-google-maps';
import { ConfigProvider, Flex, Form, Switch, Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'translations/hooks';

import { PointOfInterestMarker } from 'business/dashboard/components/map/point-of-interest-marker';
import { GetTbmInfosQuery } from 'generated/graphql';

import styles from './index.module.scss';

const SHOW_POIS_FORM_ITEM = 'showPoisFormItem';

interface PointsIfInterestProps {
  pois: GetTbmInfosQuery['constructionSite'][number]['mapPois'];
}

export const PointsOfInterest = ({ pois }: PointsIfInterestProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const showPois = Form.useWatch(SHOW_POIS_FORM_ITEM, form);

  const map = useMap();

  const [selectedMarkerInfos, setSelectedMarkerInfos] = useState<{
    name: string;
    marker: google.maps.marker.AdvancedMarkerElement;
  } | null>(null);

  const onMarkerClick = (
    name: string,
    marker: google.maps.marker.AdvancedMarkerElement,
  ) => {
    setSelectedMarkerInfos({ name, marker });
  };

  const handleInfoWindowCloseClick = useCallback(() => {
    setSelectedMarkerInfos(null);
  }, []);

  useEffect(() => {
    if (!map) {
      return;
    }

    const listener = map.addListener('click', () => {
      handleInfoWindowCloseClick();
    });

    return () => {
      listener.remove();
    };
  }, [map, handleInfoWindowCloseClick]);

  return (
    <>
      <MapControl position={ControlPosition.LEFT_BOTTOM}>
        <Flex className={styles.poiControl}>
          <ConfigProvider
            theme={{
              components: {
                Form: { itemMarginBottom: 0, verticalLabelPadding: 0 },
              },
            }}
          >
            <Form form={form} layout="vertical">
              <Form.Item
                name={SHOW_POIS_FORM_ITEM}
                label={t('dashboard.tbm.poi.showPois')}
                className={styles.label}
                initialValue={true}
              >
                <Switch size="small" disabled={!pois.length} />
              </Form.Item>
            </Form>
          </ConfigProvider>
        </Flex>
      </MapControl>

      {showPois
        ? pois.map(({ id, latitude, longitude, name }) => {
            return (
              <PointOfInterestMarker
                key={id}
                position={{ lat: latitude, lng: longitude }}
                onMarkerClick={(marker) => onMarkerClick(name, marker)}
              />
            );
          })
        : null}

      {showPois && selectedMarkerInfos ? (
        <InfoWindow
          anchor={selectedMarkerInfos?.marker}
          headerContent={<Typography>{selectedMarkerInfos?.name}</Typography>}
          onCloseClick={handleInfoWindowCloseClick}
        />
      ) : null}
    </>
  );
};

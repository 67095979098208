import {
  AdvancedMarker,
  AdvancedMarkerProps,
  Pin,
  useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps';

import { colorPrimary } from 'ui/theme/colors';

export const PointOfInterestMarker = (
  props: Omit<AdvancedMarkerProps, 'children'> & {
    onMarkerClick: (marker: google.maps.marker.AdvancedMarkerElement) => void;
  },
) => {
  const { onMarkerClick, ...advancedMarkerProps } = props;
  const [markerRef, marker] = useAdvancedMarkerRef();

  return (
    <AdvancedMarker
      onClick={() => {
        if (marker) {
          onMarkerClick(marker);
        }
      }}
      ref={markerRef}
      {...advancedMarkerProps}
    >
      <Pin
        scale={0.75}
        background="#003a8c"
        glyphColor="white"
        borderColor={colorPrimary}
      />
    </AdvancedMarker>
  );
};

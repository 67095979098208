import { Slider, InputNumber, Space } from 'antd';

import styles from './index.module.scss';

interface SliderWithInputNumberProps {
  value?: number;
  min: number;
  max: number;
  onChange?: (value: number | null) => void;
}

export const SliderWithInputNumber = ({
  value,
  min,
  max,
  onChange,
}: SliderWithInputNumberProps) => {
  return (
    <Space direction={'horizontal'}>
      <Slider
        min={min}
        max={max}
        onChange={onChange}
        defaultValue={50}
        value={value}
        className={styles.slider}
      />

      <InputNumber min={min} max={max} value={value} onChange={onChange} />
    </Space>
  );
};

import { PaginationProps, TablePaginationConfig } from 'antd';
import { useState } from 'react';
import invariant from 'tiny-invariant';

const defaultCurrentPage = 1;
const defaultPageSize = 50;

import { useAppContext } from 'business/contextProviders/useAppContext';
import { useGetDataQuery } from 'business/steering/hooks/use-get-steering-data-query';
import { SteeringTable } from 'business/steering/pages/table-page/components/steering-table';

export const SteeringTableContainer = () => {
  const { currentConstructionSiteId: constructionSiteId } = useAppContext();
  invariant(constructionSiteId, 'No construction site id');

  const [pagination, setPagination] = useState<PaginationProps>({
    current: defaultCurrentPage,
    pageSize: defaultPageSize,
  });

  const { data, loading } = useGetDataQuery(constructionSiteId, pagination);

  const handlePaginationChange = (newPagination: TablePaginationConfig) => {
    setPagination({
      current: defaultCurrentPage,
      pageSize: defaultPageSize,
      ...newPagination,
    });
  };

  return (
    <SteeringTable
      data={data?.parameter_ring}
      loading={loading}
      pagination={{
        ...pagination,
        total: data?.parameter_ring_aggregate.aggregate?.count,
      }}
      onChangePagination={handlePaginationChange}
    />
  );
};

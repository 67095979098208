import dayjs from 'dayjs';
import invariant from 'tiny-invariant';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { SteeringFilters } from 'business/steering/components/steering-filters';
import { SteeringPageTemplate } from 'business/steering/components/steering-page-template';
import { SteeringGraphContainer } from 'business/steering/pages/graph-page/components/steering-graph-container';
import { SteeringFiltersProvider } from 'business/steering/providers/steering-filters-provider';
import { FilterCard } from 'ui/filter-card';

const SteeringGraphPage = () => {
  const { t } = useTranslation();

  const { currentConstructionSite } = useAppContext();
  invariant(currentConstructionSite, 'No construction site');

  return (
    <SteeringFiltersProvider
      initialFilters={{
        filterType: 'date',
        dateRange: [dayjs().subtract(7, 'days'), dayjs()],
        position: 'rear',
        scale: currentConstructionSite.tolerance,
        tolerance: currentConstructionSite.tolerance,
      }}
    >
      <SteeringPageTemplate
        title={t('steering.graph.title')}
        filters={
          <FilterCard>
            <SteeringFilters scaleEnabled positionEnabled toleranceEnabled />
          </FilterCard>
        }
      >
        <SteeringGraphContainer />
      </SteeringPageTemplate>
    </SteeringFiltersProvider>
  );
};

export default SteeringGraphPage;
